<template>
    <div class="employee-chooser-container">
      <div class="selected-employee-container">
        <div
          variant="dark"
          v-for="(employee, index) in selectedEmployees"
          :key="index + employee.id + employee.firstname"
          class="selected-employee"
        >
          <span class="employee-id"
            >{{ employee.firstname }} {{ employee.lastname }}</span
          >
          <span class="close" @click="removeEmployee(index)">x</span>
        </div>
      </div>
      <b-form-input
        v-model="search"
        placeholder="Search user by name, email..."
        size="sm"
        @input="searchEmployee"
      ></b-form-input>
  
      <div class="employee-chooser-search-container" v-if="showSearchDisplay">
        <b-list-group>
          <b-list-group-item v-if="isLoading"
            ><b-spinner small label="Small Spinner"></b-spinner>
            Searching...</b-list-group-item
          >
          <b-list-group-item v-else-if="search && search.length < 2"
            >Enter at least two(2) character.</b-list-group-item
          >
          <b-list-group-item
            v-else-if="search.length > 1 && employees.length == 0"
            >No user found for [{{ search }}]</b-list-group-item
          >
          <div v-else>
            <b-list-group-item
              button
              @click="selectEmployee(employee)"
              v-for="(employee, index) in selectableEmployees"
              :key="index + employee.id + employee.firstname"
              >{{ employee.firstname }} {{ employee.lastname }}</b-list-group-item
            >
          </div>
        </b-list-group>
      </div>
    </div>
  </template>
  
  <script>
  import { http } from "@/utils/http";
  import { endpoints } from "@/utils/endpoints";
  import { debounce } from "debounce";
  
  export default {
    name: "SearchUsers",
    props: {},
    data() {
      return {
        search: "",
        employees: [],
        isLoading: false,
        showSearchDisplay: false,
        selectedEmployees: [],
      };
    },
    watch: {
      search(v) {
        if (v.length == 0) {
          this.showSearchDisplay = false;
        }
      },
    },
    computed: {
      selectableEmployees() {
        let selectedEmployeesId = this.selectedEmployees.map(
          (employee) => employee.id
        );
  
        return this.employees.filter(
          (employee) => !selectedEmployeesId.includes(employee.id)
        );
      },
    },
    methods: {
      searchEmployee: debounce.debounce(function (search) {
        if (search.length < 2) {
          return;
        }
        this.showSearchDisplay = true;
  
        this.isLoading = true;
  
        http
          .get(`${endpoints.SEARCH_USERS}?searchTerm=${search}`)
          .then((response) => {
            this.employees = response;
          })
          .catch((error) => {
            this.$toast.error(error.message);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }),
      selectEmployee(employee) {
        let newEmployeesList = [...this.selectedEmployees, employee];
        this.selectedEmployees = newEmployeesList;
        this.employees = [];
        this.search = "";
  
        this.$emit("change", newEmployeesList);
      },
      removeEmployee(index) {
        let newSelectedEmployeesList = this.selectedEmployees;
        newSelectedEmployeesList.splice(index, 1);
  
        this.selectedEmployees = newSelectedEmployeesList;
        this.$emit("change", newSelectedEmployeesList);
      },
      reset() {
        this.employees = [];
        this.search = "";
        this.selectedEmployees = [];
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .employee-chooser-container {
    position: relative;
  
    .employee-chooser-search-container {
      position: absolute;
      width: 100%;
      z-index: 999;
    }
  }
  
  .selected-employee-container {
    display: flex;
    gap: 3px;
    margin-bottom: 5px;
    flex-wrap: wrap;
    .selected-employee {
      border: 1px solid #888ea89a;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 5px;
    }
    .close {
      color: red;
      cursor: pointer;
      transition: 1s ease-in-out;
      font-size: 16px;
  
      &:hover {
        color: rgb(92, 4, 4);
      }
    }
  }
  </style>