<template>
  <div>
    <div>
      <div>
        <b-card
          v-for="(comment, index) in task.comments"
          :key="index + comment.comment"
          :class="`comment-card ${
            comment.created_by.id === authUser.id ? 'comment-owner' : ''
          }`"
        >
          <b-card-text class="comment-text">
            {{ comment.comment }}
          </b-card-text>
          <div class="selected-file-container" v-if="comment.files.length">
            <div
              variant="dark"
              v-for="(file, index) in comment.files"
              :key="index + file.filename"
              class="selected-file"
            >
              <a :href="file.url"
                ><span class="file-id">{{ file.filename }}</span></a
              >
              <b-spinner
                label="Loading"
                class="close"
                small
                v-if="deletingAttachment === file.id"
              ></b-spinner>
              <span
                v-else
                class="close"
                @click="deleteCommentAttachment(file.id)"
                >x</span
              >
            </div>
          </div>
          <div class="comment-footer">
            <b-card-text class="small text-muted"
              >By: {{ comment.created_by.firstname }}
              {{ comment.created_by.lastname }}
            </b-card-text
            >
            <b-card-text class="small text-muted">{{
              comment.created_at | moment("calendar")
            }}</b-card-text>
          </div>
          <span
            class="comment-delete"
            v-if="comment.created_by.id === authUser.id"
          >
            <b-spinner
              label="Loading"
              small
              v-if="deleting === comment.id"
            ></b-spinner>
            <b-icon
              icon="trash"
              class="text-danger"
              @click="deleteComment(comment.id)"
              v-else
            ></b-icon>
          </span>
        </b-card>
      </div>
    </div>
    <div class="selected-file-container" v-if="commentAttachments.length">
      <div
        variant="dark"
        v-for="(file, index) in commentAttachments"
        :key="index + file.name"
        class="selected-file"
      >
        <span class="file-id">{{ file.name }}</span>
        <span class="close" @click="removeFile(index)">x</span>
      </div>
    </div>
    <b-form inline>
      <b-input-group class="w-100" size="sm">
        <b-form-input
          type="text"
          v-model="comment"
          placeholder="New comment..."
        ></b-form-input>

        <b-input-group-append>
          <b-button variant="light" size="sm" @click="chooseFiles()">
            <b-icon icon="images" />
          </b-button>
          <input
            type="file"
            id="comment-attachment"
            hidden
            multiple
            @change="onFileChange"
          />
          <b-button
            :disabled="!comment"
            variant="primary"
            size="sm"
            @click="createComment"
            ><b-spinner label="Loading" v-if="saving" small></b-spinner
            >Comment</b-button
          >
        </b-input-group-append>
      </b-input-group>
    </b-form>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";

export default {
  name: "TaskComments",
  props: ["task"],
  data() {
    return {
      commentAttachments: [],
      comment: "",
      saving: false,
      deleting: false,
      deletingAttachment: false,
    };
  },
  methods: {
    chooseFiles: function () {
      document.getElementById("comment-attachment").click();
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;

      if (!files.length) return;

      let fileList = [];
      files.forEach((file) => {
        fileList.push(file);
      });

      this.commentAttachments = [...this.commentAttachments, ...fileList];
    },

    createComment() {
      this.saving = true;
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      let formData = new FormData();
      formData.append("comment", this.comment);

      if (this.commentAttachments) {
        this.commentAttachments.forEach((file) => {
          formData.append("files[]", file);
        });
      }

      http
        .post(
          endpoints.CREATE_TASK_COMMENT.replace(":taskId", this.task.id),
          formData,
          config
        )
        .then((response) => {
          this.$toast.success(response.message);
          this.$emit("new-comment-created", []);
          this.commentAttachments = [];
          this.comment = "";
        })
        .catch((response) => {
          this.$toast.error(response.data.message);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    deleteComment(commentId) {
      if (confirm("Are you sure you want to delete this comment?")) {
        this.deleting = commentId;

        http
          .delete(
            endpoints.DELETE_TASK_COMMENT.replace(
              ":taskId",
              this.task.id
            ).replace(":commentId", commentId)
          )
          .then((response) => {
            this.$toast.success(response.message);
            this.$emit("new-comment-created", []);
          })
          .catch((response) => {
            this.$toast.error(response.data.message);
          })
          .finally(() => {
            this.deleting = false;
          });
      }
    },

    removeFile(index) {
      let newSelectedFileList = this.commentAttachments;
      newSelectedFileList.splice(index, 1);

      this.commentAttachments = newSelectedFileList;
    },
    deleteCommentAttachment(attachmentId) {
      this.deletingAttachment = attachmentId;

      http
        .delete(
          endpoints.DELETE_TASK_COMMENT_ATTACHMENT.replace(
            ":taskId",
            this.task.id
          ).replace(":attachmentId", attachmentId)
        )
        .then((response) => {
          this.$toast.success(response.message);
          this.$emit("new-comment-created", []);
        })
        .catch((response) => {
          this.$toast.error(response.data.message);
        })
        .finally(() => {
          this.deletingAttachment = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      authUser: "user",
    }),
  },
};
</script>
<style lang="scss" scoped>
.selected-file-container {
  display: flex;
  gap: 3px;
  margin-bottom: 5px;
  flex-wrap: wrap;
  .selected-file {
    border: 1px solid #888ea89a;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .close {
    color: red;
    cursor: pointer;
    transition: 1s ease-in-out;
    font-size: 16px;

    &:hover {
      color: rgb(92, 4, 4);
    }
  }
}
.comment-card {
  width: fit-content;
  margin-bottom: 5px;
  position: relative;
}

.comment-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-size: 8px !important;
}
p {
  margin-bottom: 0px;
}

.comment-text {
  color: #049a58;
  font-size: 13px;
  margin-bottom: 10px;
}

.comment-owner {
  background-color: #c4c6c9d4;
  .comment-text {
    color: black;
  }
}

.comment-delete {
  position: absolute;
  top: 0px;
  right: 5px;
  cursor: pointer;
}
</style>
