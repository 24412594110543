<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="d-md-flex justify-content-between mb-3">
            <div>
              <h3>Attendance</h3>
              <p class="text-muted">
                Mark your attendance and state your tasks for the day
              </p>
            </div>
            <div>
              <b-form-select
                v-model="selectedWeek"
                :options="weekOptions"
                @change="fetchRecords()"
              ></b-form-select>
            </div>
          </div>

          <div class="widget-content widget-content-area">
            <div v-if="loading">
              <b-row v-for="row in 3" :key="row">
                <b-col v-for="column in 6" :key="column" class="mb-2">
                  <b-skeleton-img height="150px"></b-skeleton-img>
                </b-col>
              </b-row>
            </div>
            <b-table-simple v-else caption-top responsive bordered>
              <b-thead head-variant="light">
                <b-tr>
                  <b-th></b-th>
                  <b-th
                    class="text-center"
                    v-for="(period, index) in periods"
                    :key="index"
                  >
                    {{ period | moment("dddd") }}<br />
                    <small>{{ period | moment("MMM Do YYYY") }}</small>
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  :class="{ 'table-primary': user.id == authUser.id }"
                  v-for="user in users"
                  :key="user.id"
                >
                  <b-th>
                    <div class="d-md-flex align-items-center">
                      <b-img
                        :src="user.image"
                        alt="image"
                        rounded="circle"
                        width="35"
                        height="35"
                        class="mr-2"
                        onerror="this.onerror=null;this.src='/img/icons/apple-touch-icon.png';"
                      ></b-img>
                      <span>
                        <span
                          class="badge badge-secondary"
                          v-if="user.id == authUser.id"
                        >
                          YOU
                        </span>
                        {{ user.firstname }} {{ user.lastname }}<br />
                        <small>
                          {{ user.role | startCase }}
                        </small>
                        <br />
                        <b-btn
                          variant="link p-1"
                          size="sm"
                          class="mt-2"
                          :class="{ 'btn-info': user.id != authUser.id }"
                          @click="openUserWeekStat(user.id)"
                        >
                          Week completed: {{ user.completed_tasks }}
                        </b-btn>
                      </span>
                    </div>
                  </b-th>
                  <b-td
                    v-for="(period, index) in periods"
                    :key="index"
                    :set="
                      (attendance = getUserAttendanceForDay(period, user.id))
                    "
                  >
                    <button
                      type="button"
                      class="add-btn text-left"
                      @click="handleConfirmIncidentReport(period, user.id)"
                      :disabled="loadingTasks"
                    >
                      <span
                        class="badge badge-danger mb-2"
                        style="white-space: normal"
                        v-if="attendance"
                      >
                        <b-icon icon="pin"></b-icon>
                        {{ attendance.created_at | moment("hh:mm:ss a") }}
                      </span>
                      <div
                        class="d-flex"
                        :class="[
                          attendance
                            ? 'justify-content-between'
                            : 'justify-content-center',
                        ]"
                      >
                        <div v-if="attendance">
                          <span class="badge badge-primary mb-1">
                            Total: {{ attendance.tasks_count }}
                          </span>
                          <br />
                          <span class="badge badge-warning mb-1">
                            Incidents: {{ attendance.incident_tasks }}
                          </span>
                          <br />
                          <span class="badge badge-success mb-1">
                            Completed: {{ attendance.completed_tasks }}
                          </span>
                          <br />
                          <span class="badge badge-info mb-1">
                            Feedbacks: {{ attendance.feedbacks_count }}
                          </span>
                          <br />
                          <span class="badge badge-dark mb-1">
                            Collaborating Tasks:
                            {{ attendance.collaborating_tasks }}
                          </span>
                        </div>
                        <span v-if="canMarkAttendance(period, user.id)">
                          <b-icon icon="plus-lg" font-scale="3.5"></b-icon>
                        </span>
                      </div>
                    </button>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <b-modal
              id="confirm-incident-report"
              size="lg"
              centered
              scrollable
              title="Confirm Incident Report"
              class="confirm-incident-report-modal"
            >
              <template>
                <div>
                  <h6>Have you reported incidents that occurred yesterday?</h6>
                  <b-form-group>
                    <b-form-radio
                      v-model="confirmIncidentReport"
                      name="confirm-incident-report"
                      value="yes"
                      >Yes i have</b-form-radio
                    >
                    <b-form-radio
                      v-model="confirmIncidentReport"
                      name="confirm-incident-report"
                      value="no"
                      >No i have not</b-form-radio
                    >
                    <b-form-radio
                      v-model="confirmIncidentReport"
                      name="confirm-incident-report"
                      value="no-incident"
                      >There is no incident to report</b-form-radio
                    >
                  </b-form-group>
                  <b-alert :show="confirmIncidentReport === 'no'" variant="info"
                    >You will be redirected to the incident page to create
                    yesterday's incident</b-alert
                  >
                </div>
              </template>
              <template #modal-footer>
                <b-button
                  size="md"
                  variant="primary"
                  @click="markAttendance()"
                  :disabled="!confirmIncidentReport"
                >
                  Proceed
                </b-button>
              </template>
            </b-modal>

            <b-modal
              id="attendance-modal"
              size="xl"
              @hide="fetchRecords()"
              centered
              scrollable
            >
              <template #modal-title>
                <span v-if="canMarkAttendance(selectedDate, selectedUserId)">
                  Mark Attendance / Update Tasks
                </span>
                <span v-else>Attendance Tasks</span>
                <br />
                <span
                  class="small text-primary font-weight-bold"
                  v-if="attendanceRecord"
                >
                  Clocked-In:
                  {{
                    attendanceRecord.created_at
                      | moment("dddd, MMMM Do YYYY, hh:mm:ss a")
                  }}
                </span>
              </template>

              <div v-if="attendanceRecord">
                <b-list-group>
                  <draggable
                    v-model="attendanceRecord.tasks"
                    tag="span"
                    @end="handleSortedTasks"
                    handle=".handle"
                  >
                    <b-list-group-item
                      v-for="(task, index) in attendanceRecord.tasks"
                      :key="index + task.id"
                    >
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <div
                          class="d-flex align-items-center"
                          style="gap: 10px"
                        >
                          <div
                            style="display: flex; flex-direction: column"
                            class="handle"
                          >
                            <i class="fa fa-align-justify"></i>
                            <i class="fa fa-align-justify"></i>
                            <i class="fa fa-align-justify"></i>
                          </div>
                          <div>
                            <div class="text-muted mb-1">
                              <b-icon icon="pin"></b-icon>
                              {{ task.created_at | moment("calendar") }}
                              <span
                                v-if="task.completed_at"
                                class="font-weight-bold text-success"
                              >
                                <b-icon icon="check-circle"></b-icon>
                                {{ task.completed_at | moment("hh:mm:ss a") }}
                              </span>
                              <span
                                v-if="task.deleted_at"
                                class="font-weight-bold text-danger"
                              >
                                <b-icon icon="trash"></b-icon>
                                {{ task.deleted_at | moment("hh:mm:ss a") }}
                              </span>
                            </div>
                            <span class="mr-1">({{ index + 1 }})</span>
                            <span>
                              {{ task.title }}
                              <span :class="getGravityClass(task)">
                                {{ getGravityTitle(task) }}
                              </span>
                              <span
                                class="small text-danger"
                                v-if="task.deleted_at"
                              >
                                <br />
                                {{ task.deleted_reason }}
                              </span>
                            </span>
                            <br />
                            <router-link
                              :to="{
                                name: 'View Incident Report',
                                params: { id: task.incident_id },
                              }"
                              v-if="task.incident_id"
                            >
                              Incident #{{ task.incident_id }}
                            </router-link>
                          </div>
                        </div>
                        <div>
                          <b-button-group
                            size="sm"
                            v-if="
                              canMarkAttendance(selectedDate, selectedUserId) &&
                              !task.deleted_at
                            "
                          >
                            <b-button
                              variant="light"
                              class="bg-transparent border-white shadow-none p-1"
                              v-if="!task.incident_id && isTaskCreator(task)"
                            >
                              <b-form-checkbox
                                @change="completeAttendanceTask(task.id)"
                                :checked="task.completed_at ? true : false"
                                switch
                              />
                            </b-button>
                            <b-button
                              variant="light"
                              class="bg-transparent border-white shadow-none p-1"
                              @click="prepareDeleteTask(index, task.id)"
                              v-if="
                                !task.incident_id &&
                                showDeleteInput != index &&
                                isTaskCreator(task)
                              "
                            >
                              <b-icon icon="trash" class="text-danger"></b-icon>
                            </b-button>
                          </b-button-group>
                        </div>
                      </div>

                      <form @submit.prevent="deleteTask(index, task.id)">
                        <b-input-group
                          variant="warning"
                          size="sm"
                          v-if="showDeleteInput == index"
                          class="mt-2"
                        >
                          <b-form-input
                            type="text"
                            placeholder="Why are you deleting this task?"
                            minlength="5"
                            maxlength="191"
                            v-model="deletedReason"
                            required
                          ></b-form-input>
                          <b-input-group-append>
                            <b-button
                              variant="dark"
                              type="button"
                              title="Cancel"
                              @click="cancelDeleteTask()"
                              :disabled="deletingTask"
                            >
                              <b-icon icon="x"></b-icon>
                            </b-button>
                            <b-button
                              variant="danger"
                              title="Delete"
                              type="submit"
                              :disabled="deletingTask"
                            >
                              <b-icon icon="trash"></b-icon>
                            </b-button>
                          </b-input-group-append>
                        </b-input-group>
                      </form>

                      <div
                        class="attendance-task-footer"
                        v-if="task.collaborators.length"
                      >
                        <div class="collaborators">
                          <span>Collaborators: </span>
                          <div
                            v-for="(
                              collaborator, index
                            ) in task.collaborators"
                            :key="index"
                          >
                            <span class="bg-primary px-1 rounded"
                              >{{ collaborator.firstname }}
                              {{ collaborator.lastname }}</span
                            >
                          </div>
                        </div>
                        <div class="task-collaborators">
                          <div v-if="task.created_by">
                            <span>Creator: </span>
                            <span class="user-badge" v-if="isTaskCreator(task)">
                              You
                            </span>
                            <span class="user-badge" v-else>
                              {{ task.created_by.firstname }}
                              {{ task.created_by.lastname }}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="attendance-task-footer" @click="setCommentVisible(task.id)">
                        <div
                          class="comment-toggle"
                        >
                          <b-icon icon="chat-left-dots-fill" class=""></b-icon>
                          <span
                            >Comments
                            <span v-if="task.comments.length">({{ task.comments.length }})</span></span
                          >
                          <b-icon icon="caret-down-fill" class="pull-right"></b-icon>
                        </div>
                      </div>
                      <div v-if="showComments == task.id">
                        <b-collapse
                          :id="`collapse-${task.id}`"
                          class="mt-2"
                          :visible="showComments == task.id"
                        >
                          <task-comments
                            :task="task"
                            @new-comment-created="
                              fetchAttendanceRecord(task.attendance_id)
                            "
                          />
                        </b-collapse>
                      </div>
                    </b-list-group-item>
                  </draggable>
                </b-list-group>
              </div>

              <hr />

              <div v-if="canMarkAttendance(selectedDate, selectedUserId)">
                <h6 class="font-weight-bold">Add New Tasks</h6>
                <p class="text-muted" v-if="!attendanceRecord">
                  Remember that your incomplete tasks will be automatically
                  moved to today, so you don't have to add them afresh, the
                  system will do that for you
                </p>
                <form
                  method="post"
                  @submit.prevent="addNewTask($event)"
                  @keydown="addNewTask($event)"
                >
                  <b-form-group
                    v-for="(task, index) in attendanceTasks"
                    :key="index"
                  >
                    <b-card
                      :header="`Task ${JSON.stringify(index + 1)}`"
                      style="position: relative"
                    >
                      <div class="d-flex form-input-crm-web">
                        <b-form-input
                          class="form-control task-description"
                          type="text"
                          :placeholder="`Task ${index + 1} description`"
                          :autofocus="task.focus"
                          v-model.trim="attendanceTasks[index].task"
                          maxlength="191"
                        ></b-form-input>
                        <b-form-select
                          class="form-control crm-gravity"
                          v-model="attendanceTasks[index].gravity"
                          name="attendance-gravity"
                          id="attendance-gravity"
                          :options="attendanceTaskGravity"
                        >
                        </b-form-select>
                      </div>
                      <div class="employee-chooser-tasks mt-2">
                        <label>Add Other Employees to this tasks</label>
                        <search-users
                          ref="taskEmployeeUser"
                          @change="
                            (employees) =>
                              handleCollaboratorsChange(employees, index)
                          "
                        />
                      </div>

                      <div class="task-close">
                        <b-icon
                          v-if="attendanceTasks.length > 1"
                          icon="x"
                          @click="deleteTask(index, task.id)"
                        ></b-icon>
                      </div>
                    </b-card>
                  </b-form-group>
                  <p v-if="attendanceTasks.length == 1">
                    Press 'Enter' to add more tasks
                  </p>

                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    :disabled="attendanceTasks[0].task == '' || saving"
                    @click="saveAttendanceTasks()"
                  >
                    <b-spinner label="Loading" v-if="saving" small></b-spinner>
                    Submit New Tasks
                  </button>
                </form>
              </div>

              <!-- Feedback -->
              <div class="" v-if="attendanceRecord">
                <h5 class="px-3 py-2">Feedbacks</h5>

                <b-list-group class="rounded-0">
                  <b-list-group-item
                    class="flex-column align-items-start"
                    v-for="(feedback, index) in attendanceRecord.feedbacks"
                    :key="index"
                  >
                    <div class="d-flex justify-content-between mb-2">
                      <span class="w-75">
                        <h6 class="font-weight-bold mb-0">
                          {{
                            feedback.user
                              ? `${feedback.user.firstname} ${feedback.user.lastname}`
                              : ""
                          }}
                        </h6>
                        <small>
                          {{ formatFancyDateFull(feedback.created_at) }}
                        </small>
                      </span>
                      <small>
                        {{ feedback.user ? feedback.user.role : "" }}
                      </small>
                    </div>

                    <p style="white-space: pre-wrap">
                      {{ feedback.feedback }}
                    </p>
                  </b-list-group-item>
                </b-list-group>

                <form
                  method="post"
                  @submit.prevent="addNewFeedback()"
                  @keydown="attendanceFeedbackForm.onKeydown($event)"
                  class="border-top border-primary"
                  v-if="!canMarkAttendance(selectedDate, selectedUserId)"
                >
                  <b-form-group
                    :invalid-feedback="
                      attendanceFeedbackForm.errors.get('feedback')
                    "
                    :state="!attendanceFeedbackForm.errors.has('feedback')"
                  >
                    <b-form-textarea
                      rows="2"
                      class="rounded-0 border-primary"
                      placeholder="Enter your feedback"
                      :state="
                        attendanceFeedbackForm.errors.has('feedback')
                          ? false
                          : null
                      "
                      v-model="attendanceFeedbackForm.feedback"
                      required
                    ></b-form-textarea>
                  </b-form-group>

                  <b-btn
                    type="submit"
                    class="rounded-0"
                    variant="outline-primary"
                    :disabled="attendanceFeedbackForm.busy"
                    block
                  >
                    <b-spinner
                      label="Loading"
                      v-if="attendanceFeedbackForm.busy"
                      small
                    ></b-spinner>
                    Submit
                  </b-btn>
                </form>
              </div>

              <template #modal-footer="{ cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                  Close
                </b-button>
              </template>
            </b-modal>

            <b-modal
              id="user-week-stat"
              title="User Attendance Tasks"
              centered
              scrollable
            >
              <b-progress
                v-if="loadingTasks"
                :value="40"
                :max="100"
                show-progress
                animated
              ></b-progress>

              <b-list-group>
                <b-list-group-item
                  v-for="(task, index) in userTasks"
                  :key="index"
                >
                  <div>
                    <div class="text-muted mb-1">
                      <b-icon icon="pin"></b-icon>
                      {{ task.created_at | moment("calendar") }}
                      <span
                        v-if="task.completed_at"
                        class="font-weight-bold text-success"
                      >
                        <b-icon icon="check-circle"></b-icon>
                        {{ task.completed_at | moment("hh:mm:ss a") }}
                      </span>
                      <span
                        v-if="task.deleted_at"
                        class="font-weight-bold text-danger"
                      >
                        <b-icon icon="trash"></b-icon>
                        {{ task.deleted_at | moment("hh:mm:ss a") }}
                      </span>
                    </div>
                    <span class="mr-1">({{ index + 1 }})</span>
                    <span>
                      {{ task.title }}
                      <span class="small text-danger" v-if="task.deleted_at">
                        <br />
                        {{ task.deleted_reason }}
                      </span>
                    </span>
                    <br />
                    <router-link
                      :to="{
                        name: 'View Incident Report',
                        params: { id: task.incident_id },
                      }"
                      v-if="task.incident_id"
                    >
                      Incident #{{ task.incident_id }}
                    </router-link>
                  </div>
                </b-list-group-item>
              </b-list-group>

              <template #modal-footer="{ cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                  Close
                </b-button>
              </template>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import SearchUsers from "@/components/search-users.vue";
import TaskComments from "./TaskComments.vue";

export default {
  name: "AttendanceIndex",
  components: { draggable, SearchUsers, TaskComments },
  data() {
    return {
      periods: [],
      users: [],
      loading: true,
      attendances: [],
      selectedDate: null,
      selectedUserId: null,
      loadingTasks: false,
      saving: false,
      attendanceTasks: [
        {
          id: "",
          task: "",
          focus: false,
          gravity: "",
          collaborators: [],
        },
      ],
      attendanceTaskGravity: [
        {
          text: "Select Task Priority",
          value: "",
        },
        {
          text: "Urgent and important",
          value: "urgent_and_important",
        },
        {
          text: "Not urgent yet important",
          value: "not_urgent_yet_important",
        },
        {
          text: "Urgent but not important",
          value: "urgent_but_not_important",
        },
        {
          text: "Not urgent and not important",
          value: "not_urgent_and_not_important",
        },
      ],
      attendanceRecord: null,
      selectedWeek: this.$moment().isoWeek(),
      showDeleteInput: null,
      deletedReason: null,
      deletingTask: false,
      attendanceFeedbackForm: new this.$Form({
        feedback: "",
      }),
      userTasks: [],
      meals: {},
      confirmIncidentReport: "",
      employees: [],
      isLoading: false,
      collaborators: [],
      showComments: 0,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "user",
    }),
    today() {
      return this.$moment().utc().format("YYYY-MM-DD");
    },
    weekOptions() {
      let weeksNumber = this.$moment().isoWeek();
      let weeks = [];

      for (let index = 1; index <= weeksNumber; index++) {
        let date = this.$moment(index, "W");
        weeks.push({
          value: index,
          text:
            "Week " +
            index +
            ": " +
            date.startOf("isoWeek").format("D MMM YYYY") +
            " - " +
            date.endOf("isoWeek").format("D MMM YYYY"),
        });
      }

      return weeks;
    },
    getGravityClass() {
      return (task) => {
        switch (task?.gravity) {
          case "not_urgent_yet_important":
            return "badge badge-warning";
          case "urgent_but_not_important":
            return "badge badge-dark";
          case "not_urgent_and_not_important":
            return "badge badge-success";
          case "urgent_and_important":
            return "badge badge-danger";
          default:
            return "";
        }
      };
    },
    getGravityTitle() {
      return (task) => {
        switch (task?.gravity) {
          case "not_urgent_yet_important":
            return "Not urgent yet important";
          case "urgent_but_not_important":
            return "Urgent but not important";
          case "not_urgent_and_not_important":
            return "Not urgent and not important";
          case "urgent_and_important":
            return "Urgent and important";
          default:
            return "";
        }
      };
    },
  },
  methods: {
    setCommentVisible(taskId) {
      if (this.showComments == taskId) {
        this.showComments = 0;
      } else {
        this.showComments = taskId;
      }
    },
    isTaskCreator(task) {
      return task?.attendance?.user_id == this.authUser?.id;
    },
    sort(tasks) {
      return tasks.sort((a, b) => {
        return a.priority - b.priority;
      });
    },
    handleSortedTasks() {
      this.saving = true;
      http
        .post(
          endpoints.SORT_ATTENDANCE_TASK.replace(
            ":attendanceId",
            this.attendanceRecord.id
          ),
          {
            tasks: this.attendanceRecord.tasks,
          }
        )
        .then((response) => {
          this.$toast.success(response.message);
        })
        .catch((response) => {
          this.$toast.error(response.data.message);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    fetchRecords() {
      this.attendanceRecord = null;
      http
        .get(endpoints.FETCH_ATTENDANCES + `?week=${this.selectedWeek}`)
        .then((response) => {
          let data = response.data;

          this.periods = data.periods;
          this.users = data.users;
          this.attendances = data.attendances;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error(error?.response?.data?.message);
        });
    },
    canMarkAttendance(date, userId) {
      let realDate = this.$moment(date, "YYYY-MM-DD").utc();

      if (!realDate.isSame(this.today)) {
        return false;
      }

      if (userId != this.authUser.id) {
        return false;
      }

      return true;
    },
    getUserAttendanceForDay(date, userId) {
      let realDate = this.$moment(date, "YYYY-MM-DD").utc();

      return this.attendances.filter((attendance) => {
        return (
          this.$moment(attendance.created_at, "YYYY-MM-DD")
            .utc()
            .isSame(realDate) && attendance.user_id == userId
        );
      })[0];
    },
    handleConfirmIncidentReport(date, userId) {
      this.selectedDate = date;
      this.selectedUserId = userId;

      let attendance = this.getUserAttendanceForDay(
        this.selectedDate,
        this.selectedUserId
      );

      if (attendance) {
        return this.markAttendance();
      }

      this.$bvModal.show("confirm-incident-report");
    },

    markAttendance() {
      if (this.confirmIncidentReport !== "no") {
        this.$bvModal.hide("confirm-incident-report");

        let attendance = this.getUserAttendanceForDay(
          this.selectedDate,
          this.selectedUserId
        );

        if (attendance) {
          this.fetchAttendanceRecord(attendance.id);
        }

        //make an Api call here
        if (!attendance) {
          this.rolloverPreviousDay();
        }

        this.$bvModal.show("attendance-modal");
      } else {
        this.$router.push("/incidents/create");
      }
    },

    rolloverPreviousDay() {
      http
        .put(endpoints.ROLLOVER_PREVIOUS_DAY)
        .then((response) => {
          this.attendanceTasks = [
            {
              id: "",
              task: "",
              focus: false,
              gravity: "",
              collaborators: [],
            },
          ];
          this.$refs.taskEmployeeUser.forEach((element) => {
            element.reset();
          });
          this.fetchAttendanceRecord(response.data.attendance.id);
        })
        .catch((response) => {
          this.$toast.error(response.data.message);
        })
        .finally(() => {
          this.saving = false;
        });
    },

    addNewTask() {
      if (event.key == "Enter") {
        this.attendanceTasks.push({
          id: "",
          task: "",
          focus: true,
          gravity: "",
          collaborators: [],
        });
      }

      return false;
    },
    prepareDeleteTask(index) {
      this.showDeleteInput = index;
    },
    cancelDeleteTask() {
      this.showDeleteInput = null;
      this.deletedReason = null;
    },
    deleteTask(index, taskId = null) {
      if (!taskId) {
        this.attendanceTasks.splice(index, 1);
        return;
      }

      this.deleteAttendanceTask(taskId);
    },
    saveAttendanceTasks() {
      let tasks = this.attendanceTasks
        .filter((attendanceTask) => attendanceTask.task != "")
        .map((attendanceTask) => {
          let task = { title: attendanceTask.task };
          if (attendanceTask.gravity) {
            task["gravity"] = attendanceTask.gravity;
          }

          if (attendanceTask.collaborators?.length) {
            task["collaborators"] = attendanceTask.collaborators;
          }

          return task;
        });

      this.saving = true;

      http
        .post(endpoints.CREATE_ATTENDANCE, {
          tasks: tasks,
        })
        .then((response) => {
          this.$toast.success(response.message);
          this.attendanceTasks = [
            {
              id: "",
              task: "",
              focus: false,
              gravity: "",
              collaborators: [],
            },
          ];
          this.$refs.taskEmployeeUser.forEach((element) => {
            element.reset();
          });
          this.fetchAttendanceRecord(response.data.attendance.id);
        })
        .catch((response) => {
          this.$toast.error(response.data.message);
        })
        .finally(() => {
          this.saving = false;
        });
    },
    fetchAttendanceRecord(attendanceId) {
      this.loadingTasks = true;

      if (this.attendanceRecord != null) {
        attendanceId = attendanceId != this.attendanceRecord.id
          ? this.attendanceRecord.id
          : attendanceId
      }

      http
        .get(endpoints.FETCH_ATTENDANCE.replace(":attendanceId", attendanceId))
        .then((response) => {
          this.attendanceRecord = response.data.attendance;
          this.loadingTasks = false;
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message ||
              "Something went wrong while fetching the attendance tasks"
          );
        });
    },
    deleteAttendanceTask(taskId) {
      if (this.deletedReason.length < 5) {
        this.$toast.error("Please provide a good reason for deleting the task");
        return;
      }

      this.deletingTask = true;

      http
        .post(
          endpoints.DELETE_ATTENDANCE_TASK.replace(
            ":attendanceId",
            this.attendanceRecord.id
          ).replace(":taskId", taskId),
          {
            reason: this.deletedReason,
            _method: "DELETE",
          }
        )
        .then(() => {
          this.$toast.success("Task deleted successfully.");
          this.fetchAttendanceRecord(this.attendanceRecord.id);
          this.cancelDeleteTask();
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message ||
              "Something went wrong while fetching the attendance tasks"
          );
        })
        .finally(() => {
          this.deletingTask = false;
        });
    },
    completeAttendanceTask(taskId) {
      this.loadingTasks = true;

      http
        .patch(
          endpoints.COMPLETE_ATTENDANCE_TASK.replace(
            ":attendanceId",
            this.attendanceRecord.id
          ).replace(":taskId", taskId)
        )
        .then((response) => {
          this.$toast.success(response.message);
          this.fetchAttendanceRecord(this.attendanceRecord.id);
          this.loadingTasks = false;
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message ||
              "Something went wrong while fetching the attendance tasks"
          );
        });
    },
    addNewFeedback() {
      this.attendanceFeedbackForm
        .post(
          endpoints.STORE_ATTENDANCE_FEEDBACK.replace(
            ":attendanceId",
            this.attendanceRecord.id
          )
        )
        .then((response) => {
          this.$toast.success(response.message);
          this.fetchAttendanceRecord(this.attendanceRecord.id);
          this.attendanceFeedbackForm.reset();
        })
        .catch((error) => {
          this.$error.error(error);
        });
    },
    openUserWeekStat(userId) {
      this.userTasks = [];

      this.$bvModal.show("user-week-stat");

      this.loadingTasks = true;

      http
        .get(
          endpoints.FETCH_USER_ATTENDANCE_TASKS.replace(":userId", userId) +
            `?week=${this.selectedWeek}&completed=1`
        )
        .then((response) => {
          this.userTasks = response.data.attendance_tasks;
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message ||
              "Something went wrong while fetching the user's attendance tasks"
          );
        })
        .finally(() => {
          this.loadingTasks = false;
        });
    },
    handleCollaboratorsChange(employees, index) {
      const employeeIds = employees.map((e) => e.id);
      this.attendanceTasks[index] = {
        ...this.attendanceTasks[index],
        collaborators: employeeIds,
      };
    },
  },
  mounted() {
    this.loading = true;
    this.fetchRecords();
  },
};
</script>
<style lang="scss" scoped>
.task-collaborators {
  display: flex;
  margin-left: 25px;
  gap: 20px;
  justify-content: flex-end;
  flex-wrap: wrap;
  .user-badge {
    border: 1px solid #4a5798;
    padding: 1px 5px;
    border-radius: 3px;
    background-color: #4a5798;
    color: white;
    margin: 2px;
    display: inline-block;
    font-size: 10px;
  }
  .collaborators {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
}

.table {
  .thead-light {
    th {
      background-color: unset;
    }
  }
  thead {
    tr {
      height: 100px;

      th {
        vertical-align: middle;
      }
    }
  }
  tbody {
    tr {
      min-height: 150px;

      th,
      td {
        vertical-align: middle;
        width: 200px;
      }

      td {
        padding: 0;

        .add-btn {
          background-color: unset;
          width: 100%;
          min-height: 150px;
          border: 0;

          &.no-click {
            cursor: not-allowed;
          }

          &:hover {
            background-color: #f7f7f7;
          }
        }
      }
    }
  }
}
.attendance-task-footer {
  border: 0.1px solid #e4e5ea;
  background-color: rgb(249, 249, 249);
  padding: 5px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .comment-toggle {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

@media (max-width: 575px) {
  .task-collaborators {
    margin-left: 0px;
    gap: 5px;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .attendance-task-footer {
    flex-wrap: wrap;
  }
}
</style>

<style lang="scss">
#confirm-incident-report .modal-lg {
  max-width: 500px;
}
.task-close {
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
  color: red;
}
.form-input-crm-web {
  flex-wrap: wrap;
  gap: 10px;

  .task-description {
    flex: 1;
  }
  .crm-gravity {
    width: 30%;
  }
}

@media (max-width: 575px) {
  .form-input-crm-web {
    .crm-gravity {
      width: 100%;
    }
  }
}
</style>